import React from "react";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <div className="contact"></div>
    </>
  );
};

export default Contact;
