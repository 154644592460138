import React from "react";
import "./work.css";
import WorkFilter from "./WorkFilter";

const Work = () => {
  return (
    <>
      <div className="work">
        <div className="work-header">
          <p>
            Creating next level <br />
            digital products
          </p>
        </div>
        <div className="work-content">
          <WorkFilter />
        </div>
      </div>
    </>
  );
};

export default Work;
