import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import Footerlogo from "../../assets/ajay-logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-top">
          <div className="top-left">
            <p>
              <img src={Footerlogo} alt="ajay-logo" /> Let's work <br />
              together
            </p>
          </div>
          <div className="top-right">
            <Link to="/">Home</Link>

            <Link to="/work">Work</Link>

            <Link to="/about">About</Link>

            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="footer-mid">
          <div className="address">
            <button>Kathmandu</button>
          </div>
          <div className="email">
            <button>ajaysrchaudhary@gmail.com</button>
          </div>
          <div className="phone">
            <button>+977 9863211232</button>
          </div>
          <div className="circle"></div>
        </div>
        <div className="footer-bottom">
          <div className="copyright">
            <p>All rights reserved @ Margin Tech 2023</p>
          </div>
          <div className="footer-social-icon">
            <a
              href="https://www.instagram.com/__ajaychaudhary/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/ajay-chaudhary-b64214242/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://github.com/srajay"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
            </a>
            <a
              href="https://www.youtube.com/@rezilong"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
