import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Work from "./workpage/Work";
import About from "./aboutpage/About";
import Contact from "./contactpage/Contact";

const BodySection = () => {
  return (
    <>
      <div className="bodysection">
        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/work" exact element={<Work />}></Route>
          <Route path="/about" exact element={<About />}></Route>
          <Route path="/contact" exact element={<Contact />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default BodySection;
