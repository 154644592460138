import React from "react";
import "./worksection.css";
import { Data } from "./WorkData";

const WorkSection = () => {
  return (
    <>
      <div className="worksection">
        <div className="worksection-header">
          <p>Work</p>
        </div>
        <div className="worksection-container">
          {Data.map((items) => (
            <div key={items.worknum} className="worksection-card">
              <a href={items.link} target="_blank" rel="noopener noreferrer">
                <div className="worksection-img">
                  <img src={items.img} alt={items.title} />
                </div>
                <div className="worksection-title">
                  <p>{items.title}</p>
                </div>
                <div className="worksection-line"></div>
                <div className="worksection-task">
                  <p>{items.task}</p>
                  <p>{items.date}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WorkSection;
