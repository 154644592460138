import React from "react";
import "./certificatesection.css";
import Certifi1 from "../../assets/certifi1.png";
import Certifi2 from "../../assets/certifi2.png";
import Certifi3 from "../../assets/certifi3.png";

const CertificateSection = () => {
  return (
    <>
      <div className="certificatesection">
        <div className="certificate-header">
          <p>CERTIFICATE</p>
        </div>
        <div className="certificate-img">
          <img src={Certifi1} alt="reactjs" />
          <img src={Certifi2} alt="django" />
          <img src={Certifi3} alt="mern" />
          <img src={Certifi1} alt="reactjs" />
        </div>
      </div>
    </>
  );
};

export default CertificateSection;
