import React from "react";
import "./aboutsection.css";
import { Data } from "./AboutData";

const AboutSection = () => {
  return (
    <>
      <div className="aboutsection">
        <div className="aboutsection-header">
          <p>I can help you with . . .</p>
        </div>
        <div className="aboutsection_card-container">
          {Data.map((item) => (
            <div key={item.cardnum} className="aboutsection-card">
              <div className="card-number">
                <p>{item.cardnum}</p>
              </div>

              <div className="aboutsection-line"></div>
              <div className="aboutsection-title">
                <p>{item.title}</p>
              </div>
              <div className="aboutsection-para">
                <p>{item.paragraph}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AboutSection;
