import React from "react";
import "./herosection.css";
import AjayImg from "../../assets/ajay-img.jpg";
import AjayCv from "../../assets/AJAY CHAUDHARY.pdf";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

const HeroSection = () => {
  return (
    <>
      <div className="herosection">
        <div className="hero-left">
          <div className="title">
            <h1>Ajay Chaudhary.</h1>
          </div>

          <div className="left-para">
            <p>
              As a designer & developer, I have a track record of creating
              intuitive and visually appealing interface and fully functioning
              application.
            </p>
            <div className="left-arrow">
              <p>
                <ArrowOutwardIcon />
              </p>
            </div>
          </div>
        </div>
        <div className="hero-img">
          <img src={AjayImg} alt="AjayImg" />
        </div>
        <div className="hero-right">
          <div className="right-arrow">
            <p>
              <ArrowOutwardIcon />
            </p>
          </div>
          <div className="right-para">
            <p>
              Freelance <br />
              Designer & Developer
            </p>
          </div>
          <div className="social-icon">
            <a
              href="https://www.instagram.com/__ajaychaudhary/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/ajay-chaudhary-b64214242/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://github.com/srajay"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
            </a>
            <a
              href="https://www.youtube.com/@rezilong"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon />
            </a>
          </div>
          <div className="download-cta">
            <button>
              <a href={AjayCv} download>
                Download CV
              </a>
            </button>
          </div>
        </div>
      </div>
      <div className="herosection-mobile">
        <div className="herosection-mobile-img">
          <img src={AjayImg} alt="ajay-img" />
        </div>
        <div className="herosection-mobile-content">
          <div className="mobile-header">
            <p>Ajay Chaudhary</p>
          </div>
          <div className="mobile-para">
            <p>
              Freelance <br />
              Designer & Developer
            </p>
          </div>
          <div className="mobile-social">
            <a
              href="https://www.instagram.com/__ajaychaudhary/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/ajay-chaudhary-b64214242/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://github.com/srajay"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
            </a>
            <a
              href="https://www.youtube.com/@rezilong"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTubeIcon />
            </a>
          </div>
          <div className="mobile-cta">
            <button>
              <a href={AjayCv} download>
                Download CV
              </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
