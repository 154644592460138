import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbarsection.css";
import AjayLogo from "../../assets/ajay-logo.png";
import HomeIcon from "@mui/icons-material/Home";
import StreamIcon from "@mui/icons-material/Stream";
import PersonIcon from "@mui/icons-material/Person";
import CallIcon from "@mui/icons-material/Call";

const NavbarSection = () => {
  const [activeNavItem, setActiveNavItem] = useState();

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };
  return (
    <>
      <div className="navbarsection">
        <div className="logo">
          <Link to="/">
            <img
              src={AjayLogo}
              alt="ajay"
              className={activeNavItem === "home" ? "nochange" : ""}
              onClick={() => handleNavItemClick("home")}
            />
          </Link>
        </div>
        <div className="nav-links">
          <Link to="/work">
            <p
              className={activeNavItem === "work" ? "active" : ""}
              onClick={() => handleNavItemClick("work")}
            >
              Work
            </p>
          </Link>
          <Link to="/about">
            <p
              className={activeNavItem === "about" ? "active" : ""}
              onClick={() => handleNavItemClick("about")}
            >
              About
            </p>
          </Link>
          <Link to="/contact">
            <p
              className={activeNavItem === "contact" ? "active" : ""}
              onClick={() => handleNavItemClick("contact")}
            >
              Contact
            </p>
          </Link>
        </div>
      </div>
      <div className="navbarsection-mobile">
        <div className="navbarcontainer-mobile">
          <div className="home-logo">
            <Link to="/">
              <p
                className={activeNavItem === "home" ? "active" : ""}
                onClick={() => handleNavItemClick("home")}
              >
                <HomeIcon sx={{ fontSize: 25 }} />
                <span>Home</span>
              </p>
            </Link>
          </div>
          <div className="work-logo">
            <Link to="/work">
              <p
                className={activeNavItem === "work" ? "active" : ""}
                onClick={() => handleNavItemClick("work")}
              >
                <StreamIcon sx={{ fontSize: 25 }} />
                <span>Work</span>
              </p>
            </Link>
          </div>
          <div className="about-logo">
            <Link to="/about">
              <p
                className={activeNavItem === "about" ? "active" : ""}
                onClick={() => handleNavItemClick("about")}
              >
                <PersonIcon sx={{ fontSize: 25 }} />
                <span>About</span>
              </p>
            </Link>
          </div>
          <div className="contact-logo">
            <Link to="/contact">
              <p
                className={activeNavItem === "contact" ? "active" : ""}
                onClick={() => handleNavItemClick("contact")}
              >
                <CallIcon sx={{ fontSize: 25 }} />
                <span>Contact</span>
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarSection;
