export const Data = [
  {
    cardnum: "01",
    title: "Design",
    paragraph:
      "With a solid track record in designing websites and apps, I deliver strong and user-friendly digital designs. Solid company branding is the foundation of any succesful website.",
  },
  {
    cardnum: "02",
    title: "Development",
    paragraph:
      "I build scalable websites from scratch that fit seamlessly with design. My focus is on user interface, transitions and interaction.",
  },
  {
    cardnum: "03",
    title: "Package",
    paragraph:
      "A complete website from concept to implementation, that's what makes me stand out. My great sense for design and my development skills enable me to create kick-ass projects.",
  },
];
