import React from "react";
import "./about.css";
import { Parallax } from "react-parallax";
import Ajayimg from "../../assets/ajay-img.jpg";
import KEC from "../../assets/kec_logo.png";
import Prasadi from "../../assets/prasadi_logo.png";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import HikingIcon from "@mui/icons-material/Hiking";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

const About = () => {
  return (
    <>
      <div className="about">
        <div className="about-header">
          <p>Empowering Your Ideas with Expert Design and Development</p>
        </div>
        <div className="about-content">
          <div className="about-text">
            <div className="about-para">
              <p>
                Step into a journey through my creative endeavors and
                professional accomplishments. As a passionate designer and
                developer, I've dedicated myself to crafting digital experiences
                that seamlessly blend innovation and aesthetics. Let's embark on
                a visual and interactive exploration of my world.
              </p>
            </div>
            <div className="dob">
              <p>Date of Birth : 2000 . 01 . 24</p>
            </div>
            <div className="interest">
              <p>
                <span>
                  <SportsSoccerIcon />
                </span>
                SPORTS
              </p>
              <p>
                <span>
                  <HikingIcon />
                </span>
                FITNESS
              </p>
              <p>
                <span>
                  <FitnessCenterIcon />
                </span>
                TRAVEL
              </p>
            </div>
          </div>
          <div className="about-img">
            <Parallax strength={600} bgImage={Ajayimg}>
              <div className="quote-content"></div>
            </Parallax>
          </div>
        </div>
        <div className="about-education">
          <div className="education-title">
            <p>EDUCATION</p>
          </div>
          <div>
            <hr />
          </div>

          <div className="kantipur">
            <div className="kantipur-logo">
              <img src={KEC} alt="kec" />
            </div>
            <div className="kantipur-text">
              <p>BE COMPUTER ENGINEER</p>
              <h2>KANTIPUR ENGINEERING COLLEGE</h2>
              <h4>LALITPUR</h4>
              <span>2019-present</span>
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div className="prasadi">
            <div className="prasadi-logo">
              <img src={Prasadi} alt="prasadi" />
            </div>
            <div className="prasadi-text">
              <p>+2 COLLEGE</p>
              <h2>PRASADI ACADEMY</h2>
              <h4>LALITPUR</h4>
              <span>2016-2018</span>
            </div>
          </div>
          <div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
